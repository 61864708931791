import { Link } from "react-router-dom"

const Donate = (props) => {
  return (
    <div>
      {/* -------------------- Block 1 -------------------- */}
      <div className="relative overflow-hidden sm:px-20 pt-5 pb-10 text-left">

        {/* BACKGROUND BLOBS */}
        <div
          className="hidden lg:block absolute bg-gold w-[168px] h-[88px] opacity-95 shadow-[0px_4px_4px_rgba(0,0,0,0.25)]"
          style={{
            borderRadius: '50%',
            bottom: '-44px',
            right: '300px',
          }}
        ></div>
        <div
          className="hidden lg:block absolute bg-gold w-[256px] h-[160px] opacity-90 shadow-[0px_4px_4px_rgba(0,0,0,0.25)]"
          style={{
            borderRadius: '50%',
            bottom: '-80px',
            right: '100px',
          }}
        ></div>

        <div
          className="hidden lg:block absolute bg-gold w-[256px] h-[160px] opacity-95 shadow-[0px_4px_4px_rgba(0,0,0,0.25)]"
          style={{
            borderRadius: '50%',
            bottom: '-50px',
            right: '-50px',
          }}
        ></div>

        {/* CONTENT */}
        <div className="flex flex-col lg:flex-row">
          {/* Donate */}
          <div className="bg-dark-green w-full lg:w-3/5 px-8 sm:px-16 py-16 sm:shadow sm:shadow-donate">
            <div className="flex flex-col gap-8">
              <div className="flex justify-between items-end gap-5">
                <h1 className="text-gold text-4xl monomaniac-one">Donate</h1>
                <div className="bg-gold h-[3px] w-full mb-1"></div>
              </div>

              <p className="text-white jet-brains-mono">
                Donations are an amazing way for you to get involved in our work!
              </p>
              <p className="text-white jet-brains-mono">
                When you donate to Al-Haqq Foundation, you are directly funding our
                ability to provide holistic educational and social services to those
                in our community.
              </p>

              <div className="flex flex-col gap-3">
                <h1 className="text-gold text-3xl monomaniac-one">Monthly Donations</h1>
                <p className="text-white jet-brains-mono">
                  Please consider providing us with monthly support. This greatly helps
                  with long-term planning and budgeting of the work we do!
                </p>
              </div>

              <div className="flex flex-col sm:flex-row gap-5">
                <Link
                  to="https://next.waveapps.com/checkouts/d6b382d0ffc84c6da3431240a3cdddd7"
                  target="_blank"
                  rel="noreferrer"
                  type="button"
                  className="text-center w-full bg-gold hover:bg-dark-gold text-2xl rounded-md px-10 pt-1 pb-2 text-black shadow-md shadow-black/100
                hover:shadow-sm hover:shadow-black/100 active:shadow active:shadow-transparent align-middle"
                  style={{
                    fontFamily: 'Monomaniac One'
                  }}
                >
                  Monthly
                </Link>

                <Link
                  to="https://next.waveapps.com/checkouts/9f1447ddb6fc49158c25ceb0d07f9215"
                  target="_blank"
                  rel="noreferrer"
                  type="button"
                  className="text-center w-full bg-gold hover:bg-dark-gold text-2xl rounded-md px-10 pt-1 pb-2 text-black shadow-md shadow-black/100
                hover:shadow-sm hover:shadow-black/100 active:shadow active:shadow-transparent align-middle"
                  style={{
                    fontFamily: 'Monomaniac One'
                  }}
                >
                  One-time
                </Link>
              </div>
            </div>
          </div>

          {/* Other stuff */}
          <div className="text-left w-full lg:w-2/5 px-8 pt-12 lg:py-16">
            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-3">
                <h1 className="text-gold text-3xl monomaniac-one">Tax Receipts</h1>
                <p className="text-black jet-brains-mono">
                  Al-Haqq Foundation is currently in the process of applying for charitable status through the Canada Revenue Agency (CRA).
                </p>
                <p className="text-black jet-brains-mono">
                  As such, we are <b>unable to provide tax receipts for donations until further notice</b>
                </p>
              </div>
              <div className="flex flex-col gap-3">
                <h1 className="text-gold text-3xl monomaniac-one">Contact Us</h1>
                <p className="text-black jet-brains-mono">
                  For any questions regarding donations, please email <a href="admin@alhaqqfoundation.ca"><b>admin@alhaqqfoundation.ca</b></a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Donate