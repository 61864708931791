import { useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import emailjs from "@emailjs/browser"
import { toast, Bounce } from "react-toastify"

const validateEmail = (email) => {
  const regex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regex.test(email)) {
    return false
  }
  return true
}

const ContactUs = (props) => {
  const [sending, setSending] = useState(false)
  const form = useRef()

  const submitForm = async (e) => {
    e.preventDefault()

    if (sending) { // prevent double sends
      return;
    }

    setSending(true)

    const toast_options = {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    }

    // form validation
    if (e.target.user_name.value.trim() === '') {
      toast.error('Please enter a name', toast_options);
      return;
    } else if (e.target.user_email.value.trim() === '') {
      toast.error('Please enter an email', toast_options);
      return;
    } else if (!validateEmail(e.target.user_email.value)) {
      toast.error('Invalid email adress', toast_options);
      return;
    } else if (e.target.message.value.trim() === '') {
      toast.error('Please enter a message', toast_options);
      return;
    }

    const resp = await emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, {
      publicKey: process.env.REACT_APP_PUBLIC_KEY
    });

    if (resp.status === 200) {
      toast.success('Message sent', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      })
      e.target.reset();
    } else {
      toast.error('Something went wrong', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      })
    }
    setSending(false)
  }

  return (
    <div>
      <div className="flex flex-col gap-10 bg-white px-10 sm:px-20 md:px-40 py-20 text-left">
        <div>
          <div className="flex justify-between items-end gap-5 mb-5">
            <h1 className="text-gold text-4xl monomaniac-one">Contact&nbsp;Us</h1>
            <div className="bg-gold h-[3px] w-full mb-1"></div>
          </div>
          <p className="text-sm jet-brains-mono">
            If you have any questions, comments, or concerns about our organization, please feel free to
            reach out through the form below and we will do our best to respond as quickly as possible. We
            also welcome you to use any of our social media, phone numbers, or email to reach out about any
            inquiries. Thank you.
          </p>
        </div>

        <form ref={form} onSubmit={submitForm}>
          <div className="flex flex-col md:flex-row items-stretch gap-4 md:gap-20">
            <div className="flex flex-col w-full md:w-1/3 gap-5">
              <div className="flex flex-col">
                <label htmlFor="name" className="text-lg text-gold mb-1 monomaniac-one">Name (Required)</label>
                <input type="text" id="name" name="user_name" className="bg-fog/[0.35] p-1 text-sm rounded" />
              </div>
              <div className="flex flex-col">
                <label htmlFor="email" className="text-lg text-gold mb-1 monomaniac-one">Email (Required)</label>
                <input type="text" id="email" name="user_email" className="bg-fog/[0.35] p-1 text-sm rounded" />
              </div>
              <div className="flex flex-col">
                <label htmlFor="subject" className="text-lg text-gold mb-1 monomaniac-one">Subject</label>
                <input type="text" id="subject" name="subject" className="bg-fog/[0.35] p-1 text-sm rounded" />
              </div>
              <button
                type="submit"
                value="send"
                className="hidden md:block mt-4 bg-gold hover:bg-dark-gold rounded-md px-5 pt-1 pb-2 text-black text-center w-1/2 shadow shadow-black/100 hover:shadow-sm hover:shadow-black/100"
                style={{
                  fontFamily: 'Monomaniac One'
                }}
              >
                Submit
              </button>
            </div>

            <div className="flex flex-col w-full md:w-2/3 h-[292px]">
              <label htmlFor="message" className="text-lg text-gold mb-1 monomaniac-one">Your Message</label>
              <textarea id="message" name="message" className="bg-fog/[0.35] p-1 resize-none text-sm rounded h-full" />
            </div>

            <button
              onClick={submitForm}
              type="button"
              className="block md:hidden mt-4 bg-gold hover:bg-dark-gold rounded-md px-5 pt-1 pb-2 text-black text-center w-full mobile:w-1/2 mx-auto shadow shadow-black/100 hover:shadow-sm hover:shadow-black/100"
              style={{
                fontFamily: 'Monomaniac One'
              }}
            >
              Submit
            </button>
          </div>
        </form>

        <div className="flex flex-col sm:flex-row gap-10 md:gap-20">
          <div className="flex flex-col w-full md:w-1/3">
            <div className="text-lg text-gold mb-2 monomaniac-one">Social Media</div>
            <div className="inline-flex gap-6 text-5xl text-black align-middle">
              <a target="_blank" rel="noreferrer" href="https://www.facebook.com/people/Al-Haqq-Foundation/61558335982609/"><FontAwesomeIcon icon={faFacebook} /></a>
              <a target="_blank" rel="noreferrer" href="https://www.instagram.com/alhaqq_foundation/"><FontAwesomeIcon icon={faInstagram} /></a>
              <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/alhaqqfoundation/"><FontAwesomeIcon icon={faLinkedin} /></a>
            </div>
          </div>
          <div className="flex flex-col gap-2 w-full md:w-2/3">
            <div className="text-lg text-gold monomaniac-one">Email</div>
            <div className="text-sm jet-brains-mono">Board Email: <a href="mailto:board@alhaqqfoundation.ca" className="text-gold underline underline-offset-4">board@alhaqqfoundation.ca</a></div>
            <div className="text-sm jet-brains-mono">General Inquiries: <a href="mailto:admin@alhaqqfoundation.ca" className="text-gold underline underline-offset-4">admin@alhaqqfoundation.ca</a></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs