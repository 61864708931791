import { logo } from "../assets/assets"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import { faFacebook, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons"
// import { Link } from "react-router-dom"
import "./styles/footer.css"

const Footer = (props) => {
  return (
    <footer className="bg-black text-white sm:px-20 py-10">
      <div className="flex flex-col sm:flex-row justify-center items-center">

        {/* Right side */}
        <div className="flex flex-row items-center w-[243px] tablet:w-1/2 text-2xl">
          <img src={logo} className="h-24" alt="Al-Haqq Logo" />
          <div
            className="leading-none text-left"
            style={{
              fontFamily: 'Monomaniac One'
            }}
          >
            Al-Haqq<br />Foundation
          </div>
        </div>

        {/* Vertical line */}
        <div className="hidden tablet:block h-48 border-r border-white mx-10 opacity-50"></div>

        {/* Left side */}
        <div className="min-w-[243px] tablet:min-w-[436px] w-1/2">
          <div
            className="grid gap-4 footer-grid justify-between py-6 lg:py-8 leading-none"
          // 93, 68, 101, 126
          >
            <div className="jet-brains-mono text-sm text-left">
              <h2 className="mb-6 text-sm text-gray-900 uppercase dark:text-white monomaniac-one text-gold">About Us</h2>
              <ul className="text-gray-500 dark:text-gray-400">
                <li className="mb-3">
                  <a href="/about-us/overview" className="hover:underline">Overview</a>
                </li>
                <li className="mb-3">
                  <a href="/about-us/our-people" className="hover:underline">Our People</a>
                </li>
                <li className="mb-3">
                  <a href="/about-us/impacts-and-reports" className="hover:underline">Impacts and<br />Reports</a>
                </li>
              </ul>
            </div>
            <div className="jet-brains-mono text-sm text-left">
              <h2 className="mb-6 text-sm text-gray-900 uppercase dark:text-white monomaniac-one text-gold">&nbsp;</h2>
              <ul className="text-gray-500 dark:text-gray-400">
                <li className="mb-3">
                  <a href="/about-us/programs" className="hover:underline">Programs</a>
                </li>
                <li className="mb-3">
                  <a href="/news" className="hover:underline">News</a>
                </li>
              </ul>
            </div>
            <div className="jet-brains-mono text-sm text-left">
              <h2 className="mb-6 text-sm text-gray-900 uppercase dark:text-white monomaniac-one text-gold">Get Involved</h2>
              <ul className="text-gray-500 dark:text-gray-400">
                <li className="mb-3">
                  <a href="/get-involved/volunteering" className="hover:underline">Volunteer</a>
                </li>
                <li className="mb-3">
                  <a href="/get-involved/sponsorships-and-collaboration" className="hover:underline">Sponsorships</a>
                </li>
              </ul>
            </div>
            <div className="jet-brains-mono text-sm text-left">
              <h2 className="w-[126px] mb-6 text-sm text-gray-900 uppercase dark:text-white monomaniac-one text-gold">Contact Us</h2>
              <ul className="text-gray-500 dark:text-gray-400 text-2xl">
                <li className="mr-3 float-left">
                  <a target="_blank" rel="noreferrer" href="https://www.facebook.com/people/Al-Haqq-Foundation/61558335982609/"><FontAwesomeIcon icon={faFacebook} /></a>
                </li>
                <li className="mr-3 float-left">
                  <a target="_blank" rel="noreferrer" href="https://www.instagram.com/alhaqq_foundation/"><FontAwesomeIcon icon={faInstagram} /></a>
                </li>
                <li className="mr-3 float-left">
                  <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/alhaqqfoundation/"><FontAwesomeIcon icon={faLinkedin} /></a>
                </li>
                <li className="float-left">
                  <a href="mailto:admin@alhaqqfoundation.ca"><FontAwesomeIcon icon={faEnvelope} /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr className="opacity-50" />
      <div className="px-4 py-6 bg-gray-100 dark:bg-gray-700 text-center sm:text-right">
        <span className="text-sm text-gray-500 dark:text-gray-300">
          BN: 746502210 RC0001
        </span>
      </div>
    </footer>
  )
}

export default Footer